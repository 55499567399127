<template>
  <div>
    <v-autocomplete
      @input="input"
      :hide-no-data="loading || !searchInput"
      :items="items"
      :label="label"
      :loading="loading"
      :search-input.sync="searchInput"
      hide-details
      item-text="name"
      item-value="key"
      return-object
      v-model="value"
    >
      <template v-slot:item="data">
        <template>
          <v-list-item-avatar>
            <img v-if="data.item.type === 'user'" :src="data.item.avatar.url" />
            <v-icon v-else-if="data.item.type === 'contact'">$profile</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-subtitle
              v-if="data.item.type === 'user'"
              :class="getCssClass(data.item.status)"
            >
              {{ $t("status." + data.item.status) }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-else-if="data.item.type === 'contact'">
              {{ data.item.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>

      <template v-slot:no-data>
        <v-list-item link v-if="searchInputIsEmail" @click="openContactEditor">
          <v-list-item-avatar>
            <v-icon>$profile</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>(neuer externer Kontakt)</v-list-item-title>
            <v-list-item-subtitle>{{ searchInput }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-else>
          <v-list-item-content>
            <v-list-item-title>Keine Treffer</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <contact-editor
      v-model="contactEditor.contact"
      :show="contactEditor.show"
      @cancel="contactEditor.show = false"
      @input="onContactEditorInput"
    ></contact-editor>
  </div>
</template>

<script>
import _debounce from "lodash/debounce";
import { v4 as uuidv4 } from "uuid";
import api from "@/api";
import { isEmail } from "@/functions";
import { AVAILABLE, AWAY, BUSY, IDLE } from "@/status";

export default {
  name: "ParticipantInput",
  components: {
    ContactEditor: () => import("@/components/ContactEditor"),
  },
  props: {
    existingParticipants: Array,
    label: String,
    participants: Array,
  },
  data() {
    return {
      contactEditor: {
        contact: null,
        show: false,
      },
      items: [],
      loading: false,
      searchInput: null,
      value: null,
    };
  },
  computed: {
    existingParticipantContactIds() {
      return this.existingParticipants
        .filter((p) => p.type === "contact")
        .map((c) => c.contactId);
    },
    existingParticipantUserIds() {
      return this.existingParticipants
        .filter((p) => p.type === "user")
        .map((u) => u.userId);
    },
    myUserId() {
      return this.$store.state.user.id;
    },
    searchInputIsEmail() {
      return isEmail(this.searchInput);
    },
  },
  created() {
    this.search = _debounce(this.search, 500);
  },
  watch: {
    searchInput() {
      this.loading = Boolean(this.searchInput);
      this.search();
    },
  },
  methods: {
    getCssClass(status) {
      if (status === AVAILABLE) return "success--text";
      if (status === BUSY) return "success--text";
      return "";
    },
    input(participant) {
      if (participant.type === "contact") {
        // generate share link UUID
        participant.shareLinkUuid = uuidv4();
      }
      this.$emit("participant-selected", participant);
      this.$nextTick(() => {
        this.searchInput = "";
        this.value = "";
      });
    },
    mapContactToParticipant({ email, id, name }) {
      return {
        contactId: id,
        email,
        key: "contact:" + id,
        name,
        scope: "JOIN",
        type: "contact",
      };
    },
    mapUserToParticipant({ avatar, id, name, status }) {
      return {
        avatar,
        key: "user:" + id,
        name,
        scope: "JOIN",
        status,
        type: "user",
        userId: id,
      };
    },
    onContactEditorInput(contact) {
      api.createContact(contact).then((response) => {
        const location = response.headers.location;
        contact.id = parseInt(location.split("/").pop(), 10);
        const participant = this.mapContactToParticipant(contact);
        this.input(participant);
        this.contactEditor.show = false;
      });
    },
    openContactEditor() {
      this.contactEditor.contact = { email: this.searchInput };
      this.contactEditor.show = true;
    },
    search() {
      if (this.searchInput) {
        let items = [];
        const contactsRequest = api.listContacts(this.searchInput);
        contactsRequest.then((response) => {
          const contacts = response.data
            .filter(
              (contact) =>
                !this.existingParticipantContactIds.includes(contact.id)
            )
            .map(this.mapContactToParticipant);
          items = items.concat(contacts);
        });
        const usersRequest = api.listUsers(this.searchInput);
        usersRequest.then((response) => {
          const users = response.data
            .filter(
              (user) =>
                !this.existingParticipantUserIds.includes(user.id) &&
                user.id !== this.myUserId
            )
            .map(this.mapUserToParticipant);
          items = items.concat(users);
        });
        Promise.all([contactsRequest, usersRequest]).then(() => {
          this.items = items;
          this.loading = false;
        });
      } else {
        this.items = [];
      }
    },
  },
  i18n: {
    messages: {
      de: {
        status: {
          [AVAILABLE]: "verfügbar",
          [AWAY]: "abwesend",
          [BUSY]: "beschäftigt",
          [IDLE]: "offline",
        },
      },
    },
  },
};
</script>

<style scoped>
.v-input {
  margin: 0;
}
.v-input >>> .v-select__selections {
  min-height: 32px;
}
</style>